import React, { lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Fallback from "./Fallback";

const MyLayout = lazy(() => import("@/components/Layout"));
const Login = lazy(() => import("@/pages/Login"));

//MPM
const MPM_ProcedureManger = lazy(() => import("@/pages/MPM/ProcedureManger"));
// 新的產線規劃維護
const MPM_ProductionLine = lazy(() => import("@/pages/MPM/ProductionLine"));
const MPM_Bom = lazy(() => import("@/pages/MPM/Bom"));

//MMS
const MMS_Pdt = lazy(() => import("@/pages/MMS/Pdt"));
const MMS_Trade = lazy(() => import("@/pages/MMS/Trade"));
const MMS_Cost = lazy(() => import("@/pages/MMS/Cost"));
const MMS_IE = lazy(() => import("@/pages/MMS/IE"));
const MMS_PdtList = lazy(() => import("@/pages/MMS/PdtList"));

//MNS
const NotificationSetting = lazy(() => import("@/pages/MNS/NotificationSetting"));

//CMS
const Notification = lazy(() => import("@/pages/CMS/Notification"));
const KPI = lazy(() => import("@/pages/CMS/KPI"));
const Maintenance = lazy(() => import("@/pages/CMS/Maintenance"));

// LMS
const LabelTemplate = lazy(() => import("@/pages/LMS/LabelTemplate"));
const LabelPaper = lazy(() => import("@/pages/LMS/LabelPaper"));
const LabelDate = lazy(() => import("@/pages/LMS/LabelDate"));
const LabelMachines = lazy(() => import("@/pages/LMS/LabelMachines"));
const LabelSearch = lazy(() => import("@/pages/LMS/LabelSearch"));

// QMS
const QMSManage = lazy(() => import("@/pages/QMS/QMSManage"));
const QMSRcp = lazy(() => import("@/pages/QMS/QMS(RCP)"));

// PIS
const WpcSearch = lazy(() => import("@/pages/PIS/WpcSearch"));
const WonSearch = lazy(() => import("@/pages/PIS/WonSearch"));
const InPdt = lazy(() => import("@/pages/PIS/InPdt"));
const NgReport = lazy(() => import("@/pages/PIS/NgReport"));

// PDS
const Dashboard = lazy(() => import("@/pages/PDS/DashBoard_new"));
const Dashboard_new = lazy(() => import("../pages/PDS/DashBoard_new"));
const DashBoardCard = lazy(() => import("@/pages/PDS/DashBoardCard"));
const TickerDashboard = lazy(() => import("@/pages/PDS/TickerDashboard"));
const AbcBorder = lazy(() => import("@/pages/PDS/AbcBoard"));
const OverViewLines = lazy(() => import("@/pages/PDS/OverViewLines"));
const Efficiency = lazy(() => import("@/pages/PDS/Efficiency"));
const HeaderLine = lazy(() => import("@/pages/PDS/HeaderLine"));
const ErrorPage = lazy(() => import("@/pages/404"));
const TestMarquee = lazy(() => import("@/pages/PDS/TestMarquee/index"));
const NewBoard = lazy(() => import("@/pages/PDS/NewBoard/index"));

// BDM
const PNManage = lazy(() => import("@/pages/ADM/BDM/PNManage"));
const PNManageNew = lazy(() => import("@/pages/ADM/BDM/PNManage_new"));
const EDM = lazy(() => import("@/pages/ADM/BDM/EDM"));
const OEMItemManage = lazy(() => import("@/pages/ADM/SDM/OEMManage/OEMItemManage"));
const OEMSuppManage = lazy(() => import("@/pages/ADM/SDM/OEMManage/OEMSuppManage"));
const BDM_BOM = lazy(() => import("@/pages/ADM/BDM/BOM"));

// SDM

const UserManage = lazy(() => import("@/pages/SDM/UserManage/"));
const DataAuthManage = lazy(() => import("@/pages/ADM/SDM/DataAuthManage"));
const CategoryManage = lazy(() => import("@/pages/ADM/SDM/CategoryManage"));
const Company = lazy(() => import("@/pages/SDM/Company"));
const MteqII = lazy(() => import("@/pages/SDM/Mteq"));

const Client = lazy(() => import("@/pages/SDM/Client"));
const TradingPattern = lazy(() => import("@/pages/ADM/SDM/TradingPattern"));
const FunctionParamsManage = lazy(() => import("@/pages/ADM/SDM/FunctionParamsManage"));

// TDM
const ProductionLineManage = lazy(() => import("@/pages/ADM/TDM/ProductionLineManage"));
const StringManage = lazy(() => import("@/pages/ADM/TDM/StringManage"));
// const PlantManage = lazy(() => import("@/pages/ADM/TDM/PlantManage"));
const PlantManage = lazy(() => import("@/pages/SDM/PlantManage"));
const WarehouseManage = lazy(() => import("@/pages/ADM/TDM/WarehouseManage"));
const ProcedureManage = lazy(() => import("@/pages/ADM/TDM/ProcedureManage/index"));
const PlantParamsManage = lazy(() => import("@/pages/ADM/TDM/PlantParamsManage/index"));
const SysString = lazy(() => import("@/pages/ADM/TDM/SysString"));
const SysTrans = lazy(() => import("@/pages/ADM/TDM/SysTrans"));
// EDM
const Mold = lazy(() => import("@/pages/ADM/EDM/Mold"));
const ProductionEquipment = lazy(() => import("@/pages/ADM/EDM/ProductionEquipment"));
const MeasurementEquipment = lazy(() => import("@/pages/ADM/EDM/MeasurementEquipment"));
const LogisticsEquipmentAGV = lazy(() => import("@/pages/ADM/EDM/LogisticsEquipmentAGV"));
const TransportationEquipment = lazy(() => import("@/pages/ADM/EDM/TransportationEquipment"));
const PlantMaintenance = lazy(() => import("@/pages/ADM/EDM/PlantMaintenance"));

// WMS
const PlanIn = lazy(() => import("@/pages/WMS/PlanIn"));
const PlanOut = lazy(() => import("@/pages/WMS/PlanOut"));
const StockSearch = lazy(() => import("@/pages/WMS/StockSearch"));
const InvHistory = lazy(() => import("@/pages/WMS/InvHistory/index"));
const ProInstock = lazy(() => import("@/pages/WMS/ProStock/In"));
const ProOutstock = lazy(() => import("@/pages/WMS/ProStock/Out"));
const BarChart = lazy(() => import("@/pages/WMS/BarChart"));
const InvProject = lazy(() => import("@/pages/WMS/InvProject"));
const WhReceipt = lazy(() => import("@/pages/WMS/WhReceipt"));
const WhSearch = lazy(() => import("@/pages/WMS/WhSearch"));
const TestPrint = lazy(() => import("@/pages/WMS/TestPrint"));
const ImportStock = lazy(() => import("@/pages/WMS/ImportStock"));

// APS
const ApsManage = lazy(() => import("@/pages/APS/ApsManage"));
const MainSchedule = lazy(() => import("@/pages/APS/MainSchedule"));
const PfLabelPrint = lazy(() => import("@/pages/APS/PfLabelPrint"));
const PfLabelPrintForA4 = lazy(() => import("@/pages/APS/PfLabelPrintForA4"));

// PMS
const Plan = lazy(() => import("@/pages/PMS/Plan"));
const Req = lazy(() => import("@/pages/PMS/Req"));
const Pro = lazy(() => import("@/pages/PMS/Pro"));
const Instock = lazy(() => import("@/pages/PMS/Instock"));
const GoStock = lazy(() => import("@/pages/PMS/GoStock"));
const PMSTour = lazy(() => import("@/pages/PMS/Tour"));
const PMSReturn = lazy(() => import("@/pages/PMS/Return"));
const PMSApManage = lazy(() => import("@/pages/PMS/ApManage"));
const OverView = lazy(() => import("@/pages/PMS/Overview"));
const InventoryDetail = lazy(() => import("@/pages/PMS/Report/InventoryDetail"));

// OMS
const Quo = lazy(() => import("@/pages/OMS/Quo"));
const Fod = lazy(() => import("@/pages/OMS/Fod"));
const Ord = lazy(() => import("@/pages/OMS/Ord"));
const Outstock = lazy(() => import("@/pages/OMS/Outstock"));
const Check = lazy(() => import("@/pages/OMS/Check"));
const OMSTour = lazy(() => import("@/pages/OMS/Tour"));
const OMSReturn = lazy(() => import("@/pages/OMS/Return"));
const OMSApManage = lazy(() => import("@/pages/OMS/ApManage"));

// OMS-Report
const UnShipped = lazy(() => import("@/pages/OMS/Report/UnShipped"));
const SaleSummary = lazy(() => import("@/pages/OMS/Report/SaleSummary"));
const SaleList = lazy(() => import("@/pages/OMS/Report/SaleList"));
const OrderSummary = lazy(() => import("@/pages/OMS/Report/OrderSummary"));
const OrderDetail = lazy(() => import("@/pages/OMS/Report/OrderDetail"));
const SaleDetail = lazy(() => import("@/pages/OMS/Report/SaleDetail"));

// PMS-Report
const UnPurchase = lazy(() => import("@/pages/PMS/Report/UnPurchase"));
const Diff = lazy(() => import("@/pages/PMS/Report/Diff"));
const InstockSummary = lazy(() => import("@/pages/PMS/Report/InstockSummary"));
const InventoryDiff = lazy(() => import("@/pages/PMS/Report/InventoryDiff"));
const PsiSummary = lazy(() => import("@/pages/PMS/Report/PsiSummary"));
const PurchaseSummary = lazy(() => import("@/pages/PMS/Report/PurchaseSummary"));
const PurchaseList = lazy(() => import("@/pages/PMS/Report/PurchaseList"));
const PurchaseStatistics = lazy(() => import("@/pages/PMS/Report/PurchaseStatistics"));
const PurchaseDetail = lazy(() => import("@/pages/PMS/Report/PurchaseDetail"));
const InventorySummary = lazy(() => import("@/pages/PMS/Report/InventorySummary"));

// BPM
const SignLevel = lazy(() => import("@/pages/BPM/SignLevel"));
const Event = lazy(() => import("@/pages/BPM/Event"));
const DateCtrl = lazy(() => import("@/pages/BPM/DateCtrl"));

//Test
const TestPage = lazy(() => import("@/pages/TestPage/meds/index"));
const ReactFlowTest = lazy(() => import("@/pages/TestPage/ReactFlowTest/index"));

//Test MMS 製程管理系統
const SOP = lazy(() => import("@/pages/TestPage/MMS/SOP"));
const RCP = lazy(() => import("@/pages/TestPage/MMS/RCP"));

//Test QMS
const QMSManageTest = lazy(() => import("@/pages/TestPage/QMS/QMSManage"));
const ApuiSetting = lazy(() => import("@/pages/TestPage/QMS/ApuiSetting"));
const Apui = lazy(() => import("@/pages/TestPage/QMS/Apui"));
const ApTry = lazy(() => import("@/pages/TestPage/QMS/ApTry"));
const ApPwc = lazy(() => import("@/pages/TestPage/QMS/ApPwc"));
const Inspeq = lazy(() => import("@/pages/TestPage/QMS/Inspeq"));
const QMFM = lazy(() => import("@/pages/TestPage/QMS/QMFM"));
const SPC = lazy(() => import("@/pages/TestPage/QMS/SPC"));
const PS = lazy(() => import("@/pages/TestPage/QMS/PS"));
const COM = lazy(() => import("@/pages/TestPage/QMS/COM"));
const QoNsearch = lazy(() => import("@/pages/TestPage/QMS/QoNsearch"));

//Test
const TimeLine = lazy(() => import("@/pages/TestPage/Timeline/index"));
const GanttV1 = lazy(() => import("@/pages/TestPage/GanttV1/index"));
const GanttV2 = lazy(() => import("@/pages/TestPage/GanttV2/index"));
const PlanDashboard = lazy(() => import("@/pages/TestPage/PlanDashboard/index"));
const Dragtime = lazy(() => import("@/pages/TestPage/Dragtime"));

//Test TMS
const TmsMold = lazy(() => import("@/pages/TestPage/TMS/TmsMold"));
const Cmjeq = lazy(() => import("@/pages/TestPage/TMS/Cmjeq"));
const TmsSTP = lazy(() => import("@/pages/TestPage/TMS/TmsSTP"));
const Mteq = lazy(() => import("@/pages/TestPage/TMS/Mteq"));
const PlanInManage = lazy(() => import("@/pages/TestPage/TMS/PIManage"));
const PlanOutManage = lazy(() => import("@/pages/TestPage/TMS/POManage"));
const ProInstockTms = lazy(() => import("@/pages/TestPage/TMS/ProStock/In"));
const ProOutstockTms = lazy(() => import("@/pages/TestPage/TMS/ProStock/Out"));
const BigDataDashboard = lazy(() => import("@/pages/TestPage/BigDataDashboard"));

//Test EMM
const Mfgeq = lazy(() => import("@/pages/TestPage/EMM/Mfgeq/index"));

//utils
const Icon = lazy(() => import("@/pages/Icon"));

export default function MyRoutes() {
  return (
    <BrowserRouter>
      <React.Suspense fallback={<Fallback />}>
        <Routes>
          {/* 斜線導入 */}
          <Route path="/" element={<MyLayout />}>
            <Route path="/404" element={<ErrorPage />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/icon" element={<Icon />} />
            {/* MPM */}
            <Route path="/mpm/procedure-manager" element={<MPM_ProcedureManger />} />
            <Route path="/mpm/productionLine" element={<MPM_ProductionLine />} />
            <Route path="/mpm/bom" element={<MPM_Bom />} />
            {/* Pdt */}
            <Route path="/mms/pdt" element={<MMS_Pdt />} />
            <Route path="/mms/trade" element={<MMS_Trade />} /> {/* 交易對象管理 */}
            <Route path="/mms/cost-ctrl" element={<MMS_Cost />} /> {/* 成本控制管理 */}
            <Route path="/mms/IE" element={<MMS_IE />} /> {/* 工工資料維護 */}
            <Route path="/mms/pdt-list" element={<MMS_PdtList />} /> {/* 物料清單維護 */}
            {/*TestPage */}
            <Route path="/testpage/meds/spec" element={<TestPage />} />
            <Route path="/testpage/gantt-v1" element={<GanttV1 />} />
            <Route path="/testpage/gantt-v2" element={<GanttV2 />} />
            <Route path="/testpage/timeline" element={<TimeLine />} />
            <Route path="/testpage/plan-dashboard" element={<PlanDashboard />} />
            <Route path="/testpage/dragtime" element={<Dragtime />} />
            <Route path="/testpage/bigdata-dashboard" element={<BigDataDashboard />} />
            {/*Test QMS */}
            <Route path="/testpage/qms/qms-manage" element={<QMSManageTest />} />
            <Route path="/testpage/qms/apui" element={<Apui />} />
            <Route path="/testpage/qms/apTry" element={<ApTry />} />
            <Route path="/testpage/qms/apPwc" element={<ApPwc />} />
            <Route path="/testpage/qms/inspeq" element={<Inspeq />} />
            <Route path="/testpage/qms/qmfm" element={<QMFM />} />
            <Route path="/testpage/qms/spc" element={<SPC />} />
            <Route path="/testpage/qms/com" element={<COM />} />
            <Route path="/testpage/qms/qoNsearch" element={<QoNsearch />} />
            <Route path="/testpage/qms/apuiSetting" element={<ApuiSetting />} />
            <Route path="/testpage/qms/ps" element={<PS />} />
            {/*Test MMS */}
            <Route path="/testpage/mms/sop" element={<SOP />} />
            <Route path="/testpage/mms/rcp" element={<RCP />} />
            {/*Test TMS */}
            <Route path="/testpage/tms/tms-mold" element={<TmsMold />} />
            <Route path="/testpage/tms/Cmjeq" element={<Cmjeq />} />
            <Route path="/testpage/tms/tms-stp" element={<TmsSTP />} />
            <Route path="/testpage/react-flow" element={<ReactFlowTest />} />
            <Route path="/testpage/tms/Mteq" element={<Mteq />} />
            <Route path="/testpage/tms/plan-in-manage" element={<PlanInManage />} />
            <Route path="/testpage/tms/plan-out-manage" element={<PlanOutManage />} />
            <Route path="/testpage/tms/pro-instock" element={<ProInstockTms />} />
            <Route path="/testpage/tms/pro-outstock" element={<ProOutstockTms />} />
            {/*Test EMM */}
            <Route path="/testpage/emm/Mfgeq" element={<Mfgeq />} />
            {/*  MNS */}
            <Route path="/mns/notification-setting" element={<NotificationSetting />} />
            {/* QMS */}
            <Route path="/qms/qms-manage" element={<QMSManage />} />
            <Route path="/qms/qms-rcp" element={<QMSRcp />} />
            {/* LMS */}
            <Route path="/lms/label-template" element={<LabelTemplate />} />
            <Route path="/lms/label-paper" element={<LabelPaper />} />
            <Route path="/lms/label-date" element={<LabelDate />} />
            <Route path="/lms/label-machines" element={<LabelMachines />} />
            <Route path="/lms/label-search" element={<LabelSearch />} />
            {/* PDS */}
            <Route path="/pds/dashboard" element={<Dashboard />} />
            <Route path="/pds/dashboard_new" element={<Dashboard_new />} />
            <Route path="/pds/dashboard-card" element={<DashBoardCard />} />
            <Route path="/pds/ticker-dashboard" element={<TickerDashboard />} />
            <Route path="/pds/abc-board" element={<AbcBorder />} />
            <Route path="/pds/overview-lines" element={<OverViewLines />} />
            <Route path="/pds/efficiency" element={<Efficiency />} />
            <Route path="/pds/header-line" element={<HeaderLine />} />
            <Route path="/pds/test-marquee" element={<TestMarquee />} />
            <Route path="/pds/newboard" element={<NewBoard />} />
            {/* PIS */}
            <Route path="/pis/wpc-search" element={<WpcSearch />} />
            <Route path="/pis/won-search" element={<WonSearch />} />
            <Route path="/pis/inpdt" element={<InPdt />} />
            <Route path="/pis/ng-report" element={<NgReport />} />
            {/* BDM */}
            <Route path="/bdm/pn-manage" n-manag element={<PNManage />} />
            <Route path="/bdm/pn-manage-new" element={<PNManageNew />} />
            <Route path="/bdm/edm" element={<EDM />} />
            <Route path="/bdm/oem-item-manage" element={<OEMItemManage />} />
            <Route path="/bdm/oem-supp-manage" element={<OEMSuppManage />} />
            <Route path="/bdm/bom" element={<BDM_BOM />} />
            {/* SDM */}
            <Route path="/sdm/user-manage" element={<UserManage />} />
            <Route path="/sdm/mteq" element={<MteqII />} />
            <Route path="/sdm/data-auth-manage" element={<DataAuthManage />} />
            <Route path="/sdm/category-manage" element={<CategoryManage />} />
            <Route path="/sdm/trading-pattern" element={<TradingPattern />} />
            <Route path="/sdm/company" element={<Company />} />
            <Route path="/sdm/client" element={<Client />} />
            <Route path="/sdm/function-params-manage" element={<FunctionParamsManage />} />
            <Route path="/sdm/plant-manage" element={<PlantManage />} />
            {/* TDM */}
            <Route path="/tdm/warehouse-manage" element={<WarehouseManage />} />
            <Route path="/tdm/production-line-manage" element={<ProductionLineManage />} />
            <Route path="/tdm/string-manage" element={<StringManage />} />
            <Route path="/tdm/sys-string" element={<SysString />} />
            <Route path="/tdm/translate" element={<SysTrans />} />
            <Route path="/tdm/procedure-manage" element={<ProcedureManage />} />
            <Route path="/tdm/plant-params-manage" element={<PlantParamsManage />} />
            {/* EDM */}
            <Route path="/edm/mold" element={<Mold />} />
            <Route path="/edm/production-equipment" element={<ProductionEquipment />} />
            <Route path="/edm/measurement-equipment" element={<MeasurementEquipment />} />
            <Route path="/edm/logistics-equipmentAGV" element={<LogisticsEquipmentAGV />} />
            <Route path="/edm/transportation-equipment" element={<TransportationEquipment />} />
            <Route path="/edm/plant-maintenance" element={<PlantMaintenance />} />
            {/* APS */}
            <Route path="/aps/aps-manage/:opcode" element={<ApsManage />} />
            <Route path="/aps/pf-label-print/:pageMachines" element={<PfLabelPrint />} />
            <Route path="/aps/pf-label-print-A4" element={<PfLabelPrintForA4 />} />
            <Route path="/aps/main-schedule" element={<MainSchedule />} />
            {/* IMS */}
            <Route path="/ims/plan-in" element={<PlanIn />} />
            <Route path="/ims/plan-out" element={<PlanOut />} />
            <Route path="/ims/stock-search" element={<StockSearch />} />
            <Route path="/ims/inv-history" element={<InvHistory />} />
            <Route path="/ims/pro-instock" element={<ProInstock />} />
            <Route path="/ims/pro-outstock" element={<ProOutstock />} />
            <Route path="/ims/inv-project" element={<InvProject />} />
            <Route path="/ims/wh-recipt" element={<WhReceipt />} />
            <Route path="/ims/wh-search" element={<WhSearch />} />
            <Route path="/ims/test-print" element={<TestPrint />} />
            <Route path="/ims/import-stock" element={<ImportStock />} />
            <Route path="inf/bi" element={<BarChart />} />
            {/* PMS */}
            <Route path="/pms" element={<PMSTour />} />
            <Route path="/pms/plan" element={<Plan />} />
            <Route path="/pms/req" element={<Req />} />
            <Route path="/pms/pro" element={<Pro />} />
            <Route path="/pms/instock" element={<Instock />} />
            <Route path="/pms/gostock" element={<GoStock />} />
            <Route path="/pms/return" element={<PMSReturn />} />
            <Route path="/pms/ap-manage" element={<PMSApManage />} />
            <Route path="/pms/overview" element={<OverView />} />
            {/* OMS */}
            <Route path="/oms" element={<OMSTour />} />
            <Route path="/oms/quo" element={<Quo />} />
            <Route path="/oms/fod" element={<Fod />} />
            <Route path="/oms/ord" element={<Ord />} />
            <Route path="/oms/outstock" element={<Outstock />} />
            <Route path="/oms/check" element={<Check />} />
            <Route path="/oms/return" element={<OMSReturn />} />
            <Route path="/oms/ap-manage" element={<OMSApManage />} />
            {/* OMS-Report */}
            <Route path="/oms/report-unshipped" element={<UnShipped />} />
            <Route path="/oms/report-salelist" element={<SaleList />} />
            <Route path="/oms/report-saledetail" element={<SaleDetail />} />
            <Route path="/oms/report-ordersummary/:group" element={<OrderSummary />} />
            <Route path="/oms/report-orderdetail/:group" element={<OrderDetail />} />
            <Route path="/oms/report-salesummary/:group" element={<SaleSummary />} />
            {/* PMS-Report */}
            <Route path="/pms/report-unpurchase" element={<UnPurchase />} />
            <Route path="/pms/report-diff" element={<Diff />} />
            <Route path="/pms/report-instocksummary" element={<InstockSummary />} />
            <Route path="/pms/report-inventorydiff" element={<InventoryDiff />} />
            <Route path="/pms/report-psisummary" element={<PsiSummary />} />
            <Route path="/pms/report-purchaselist" element={<PurchaseList />} />
            <Route path="/pms/report-purchasestatistics" element={<PurchaseStatistics />} />
            <Route path="/pms/report-purchasedetail/:group" element={<PurchaseDetail />} />
            <Route path="/pms/report-purchasesummary/:group" element={<PurchaseSummary />} />
            <Route path="/pms/report-inventorysummary/:group" element={<InventorySummary />} />
            <Route path="/pms/report-inventorydetail/:group" element={<InventoryDetail />} />
            {/* BPM */}
            <Route path="/bpm/sign-level" element={<SignLevel />} />
            <Route path="/bpm/event" element={<Event />} />
            <Route path="/bpm/date-ctrl" element={<DateCtrl />} />
            {/* CMS */}
            <Route path="/bpm/notification" element={<Notification />} />
            <Route path="/bpm/kpi" element={<KPI />} />
            <Route path="/bpm/maintenance" element={<Maintenance />} />
          </Route>

          <Route path="/login" element={<Login />} />
          {/* 未匹配 */}
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}
