import "antd/dist/reset.css";

import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import weekday from "dayjs/plugin/weekday";
import { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./Language/i18n";
import packageLan from "./Language/package/index";
import SystemContext from "./components/Context/SystemContext";
import MyRoutes from "./routes";
import pmsStyle from "./styles/pms";

import("dayjs/locale/en");
import("dayjs/locale/id");
import("dayjs/locale/th");
import("dayjs/locale/vi");
import("dayjs/locale/zh-cn");
import("dayjs/locale/zh-tw");

// 過濾字符串 "0001-01-01"
const initFormat = dayjs.prototype.format;
dayjs.extend((filter) => {
  dayjs.prototype.format = function (e) {
    return this.isSame(dayjs("0001-01-01"), "year") ? "" : initFormat.call(this, e);
  };
});

// 1. customParseFormat: 加入自訂格式的解析功能。原本 dayjs 只能處理有限的日期格式，
//    透過這個擴展，我們可以使用自訂的日期格式來解析日期字串。
dayjs.extend(customParseFormat);
// 2. advancedFormat: 增加更多的格式化選項。在原本的 dayjs 中，日期格式的選項有限，
//    使用這個擴展後，可以使用更高級的格式化字元，像是 "Q"（季度）、"Do"（日的序數）等。
dayjs.extend(advancedFormat);
// 3. weekday: 這個擴展讓我們可以設定或取得日期對應的一週中的哪一天（如星期一、星期二等），
//    並且允許一週的第一天不是預設的星期天。例如某些國家會將星期一作為一週的第一天。
dayjs.extend(weekday);
// 4. localeData: 允許取得與設置地區相關的資訊，像是月份、星期幾等本地化名稱。
//    使用這個擴展，可以根據使用者的地區選擇正確的日期顯示格式。
dayjs.extend(localeData);
// 5. weekOfYear: 提供了取得日期所屬的一年中的第幾週的功能。這對於那些需要根據週來組織數據的應用很有用，
//    像是日曆應用程式或週報系統。
dayjs.extend(weekOfYear);
// 6. weekYear: 這個擴展允許我們處理年份的周數概念，並確保當我們進行日期處理時，
//    能夠使用與 ISO 8601 標準對應的周數計算。
dayjs.extend(weekYear);
// 7. isoWeek: 這個擴展允許取得，SO 8601 標準對應的周數。
dayjs.extend(isoWeek);
// 8. quarterOfYear: 這個擴展允許取得一年中的季度。
dayjs.extend(quarterOfYear);
// 9. isBetween: 這個擴展允許利用區間時間做比對。
dayjs.extend(isBetween);

function changeDayjsLocale(locale) {
  switch (locale) {
    case "zh-CN":
      dayjs.locale("zh-cn");
      break;
    case "zh-TW":
      dayjs.locale("zh-tw");
      break;
    case "en-US":
      dayjs.locale("en");
      break;
    case "id-ID":
      dayjs.locale("id");
      break;
    case "th-TH":
      dayjs.locale("th");
      break;
    case "vi-VN":
      dayjs.locale("vi");
      break;
    default:
      dayjs.locale("zh-tw");
      break;
  }
}

function App() {
  const [c_systemConfig, set_c_systemConfig] = useState({
    auth: {},
    theme: "default",
    size: "middle",
    locale: "zh-TW",
    mode: "light",
  });
  // 可以知道現在網址是pms、oms、pds之中的哪一個，如果都不是就回傳pds
  const [s_theme, set_s_theme] = useState(pmsStyle);

  const [c_userData, set_c_userData] = useState({});
  const [c_corpData, set_c_corpData] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(c_systemConfig.locale);
    changeDayjsLocale(c_systemConfig.locale);
  }, [c_systemConfig.locale]);

  return (
    <div className="App">
      <SystemContext.Provider
        value={{
          c_systemConfig: c_systemConfig,
          set_c_systemConfig: set_c_systemConfig,
          c_userData: c_userData,
          set_c_userData: set_c_userData,
          c_corpData: c_corpData,
          set_c_corpData: set_c_corpData,
          set_s_theme: set_s_theme,
        }}
      >
        <I18nextProvider i18n={i18n}>
          <ConfigProvider
            locale={packageLan[c_systemConfig.locale]}
            componentSize={c_systemConfig.size}
            theme={s_theme}
          >
            <MyRoutes />
          </ConfigProvider>
        </I18nextProvider>
      </SystemContext.Provider>
    </div>
  );
}

export default App;
